import { LANDING_ROUTES_ABSOLUTE } from './landingRoutes';
import { getAbsoluteRoutes } from './utils';

const GENKAI_BASE_URL = 'https://genkai.cyberkongz.com';

// TO DO FIX later on
const ARCHETYPES = [
  'warrior',
  'sage',
  'outsider',
  'jester',
  'anti-villain',
  'fallen-hero',
  'caregiver',
  'magician',
  'henchman',
  'super-villain',
] as const;

type Archetype = typeof ARCHETYPES[number];

function getEmakiRoute(route: string) {
  return `/emaki${route}`;
}

export const MY_GENKAI_TABS = ['genkai', 'dynamicTraits', 'inventory'] as const;

export type MyGenkaiTabType = typeof MY_GENKAI_TABS[number];

const EMAKI_ROUTES = {
  HOME: getEmakiRoute(''),
  MY_GENKAI: (type?: MyGenkaiTabType) =>
    `${getEmakiRoute('/my-genkai')}${type ? `?type=${type}` : ''}`,
  MANGA: getEmakiRoute('/manga'),
  SENTAKU: getEmakiRoute('/sentaku'),
  SHOP: getEmakiRoute('/shop'),
  PFP_EDITOR: getEmakiRoute('/pfp-editor'),
};

export const GENKAI_LANDING_ROUTES = {
  HOME: '/',

  KONG_GENKAI: (id: number) => `/genkai/${id}`,

  BLOG_POST_INTRODUCING_GENKAI: LANDING_ROUTES_ABSOLUTE.BLOG_POST(
    'introducing-genkai-the-next-iteration-of-cyberkongz'
  ),

  SAGA: '/saga',
  SAGA_RESULTS: '/saga/results',
  SAGA_ARCHETYPE_RESULTS: (archetype: Archetype) => `/saga/results#${archetype}`,

  PR_KIT: '/press',
  API_SENTAKU_PRIZE_CLAIM: '/api/emaki/sentaku-prize-claim',

  EMAKI: { ...EMAKI_ROUTES },
};

export const GENKAI_LANDING_ROUTES_ABSOLUTE = getAbsoluteRoutes(
  GENKAI_LANDING_ROUTES,
  GENKAI_BASE_URL
);
