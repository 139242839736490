// Import Swiper styles
import 'swiper/css';

import { Box, Center, Flex, SystemStyleObject } from '@chakra-ui/react';
import { ChevronDownIcon, pxToRem, TRANSITIONS } from '@cyberkongz/ui';
import { useEffect, useState } from 'react';
import { Swiper, SwiperProps, useSwiper } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

// helper to autoimport necessary styles
export function SliderWrapper({ sx, ...rest }: SwiperProps & { sx?: SystemStyleObject }) {
  return (
    <Box sx={sx}>
      <Swiper {...rest} />
    </Box>
  );
}

function SliderNavigationRectangleArrow({
  onClick,
  sxBox,
  sxArrow,
}: {
  onClick?: () => void;
  sxBox?: SystemStyleObject;
  sxArrow?: SystemStyleObject;
}) {
  return (
    <Center
      onClick={onClick}
      sx={{
        transition: TRANSITIONS.standard,
        border: '1px',
        borderColor: 'text',
        w: [pxToRem(44), pxToRem(50)],
        h: [pxToRem(44), pxToRem(50)],
        opacity: !onClick ? 0.5 : 1,
        cursor: !onClick ? 'disabled' : 'pointer',
        ...sxBox,
      }}
    >
      <ChevronDownIcon
        sx={{ width: [pxToRem(16), 'auto'], transform: 'rotateZ(90deg)', ...sxArrow }}
      />
    </Center>
  );
}

export function SliderNavigationRectangle({
  slidePrev,
  slideNext,
  sxBox,
  sxArrow,
}: {
  slidePrev?: () => void;
  slideNext?: () => void;
  sxBox?: SystemStyleObject;
  sxArrow?: SystemStyleObject;
}) {
  return (
    <Flex>
      <SliderNavigationRectangleArrow
        {...{ sxArrow, sxBox: { borderRight: 'none', ...sxBox }, onClick: slidePrev }}
      />
      <SliderNavigationRectangleArrow
        {...{ sxArrow: { transform: 'rotateZ(-90deg)', ...sxArrow }, sxBox, onClick: slideNext }}
      />
    </Flex>
  );
}

export function SliderNavigationRoundedArrow({
  onClick,
  sxBox,
  sxArrow,
}: {
  onClick?: () => void;
  sxArrow?: SystemStyleObject;
  sxBox?: SystemStyleObject;
}) {
  return (
    <Center
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        left: pxToRem(5),
        transition: TRANSITIONS.standard,
        boxSize: [8, pxToRem(44)],
        bg: 'backgroundSecondary',
        borderRadius: '50%',
        cursor: 'pointer',
        ...sxBox,
      }}
    >
      <ChevronDownIcon
        width={[4, 5]}
        height="auto"
        transform={`translateX(${pxToRem(-1)}) rotateZ(90deg)`}
        sx={sxArrow}
      />
    </Center>
  );
}

export function SliderNavigationRounded({
  swiper: _swiper,
  sxBox,
  sxArrow,
}: {
  swiper?: SwiperType;
  sxBox?: SystemStyleObject;
  sxArrow?: SystemStyleObject;
}) {
  const swiperContext = useSwiper() as SwiperType | undefined;
  const swiper = _swiper || swiperContext;
  const [swiperInternal, setSwiperInternal] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const { isBeginning, isEnd } = swiperInternal;

  useEffect(() => {
    if (swiper) {
      if (!swiper.destroyed) {
        setSwiperInternal((prevState) => ({
          ...prevState,
          isBeginning: !swiper.params.loop,
          isEnd: swiper.isEnd,
        }));

        if (!swiper.params.loop) {
          swiper.on('transitionStart', ({ isBeginning, isEnd }) => {
            setSwiperInternal((prevState) => ({ ...prevState, isBeginning, isEnd }));
          });

          swiper.on('resize', ({ params, isEnd, isBeginning }) => {
            setSwiperInternal((prevState) => ({
              ...prevState,
              isBeginning: isBeginning && !params.loop,
              isEnd: isEnd,
            }));
          });
        }
      }
    }
  }, [swiper]);

  return (
    <>
      {!isBeginning ? (
        <SliderNavigationRoundedArrow {...{ onClick: () => swiper?.slidePrev(), sxBox, sxArrow }} />
      ) : null}
      {!isEnd ? (
        <SliderNavigationRoundedArrow
          {...{
            onClick: () => swiper?.slideNext(),
            sxBox: { left: 'auto', right: pxToRem(5), ...sxBox },
            sxArrow: { transform: `translateX(${pxToRem(1)}) rotateZ(-90deg)`, ...sxArrow },
          }}
        />
      ) : null}
    </>
  );
}
