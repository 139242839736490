import { Box, SystemStyleObject } from '@chakra-ui/react';

import { pxToRem } from '../theme';

export function GradientShadowOverlay({
  sx,
  top,
  height = pxToRem(210),
}: {
  sx?: SystemStyleObject;
  top?: boolean;
  height?: string | string[];
}) {
  return (
    <Box
      sx={{
        background: 'linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%)',
        position: 'absolute',
        top: top ? 0 : 'auto',
        left: 0,
        right: 0,
        bottom: top ? 'auto' : 0,
        transform: top ? 'rotateZ(180deg)' : 'none',
        height,
        ...sx,
      }}
    />
  );
}
