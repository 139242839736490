import { Box, SystemStyleObject } from '@chakra-ui/react';
import { fullSizeParentStyles, useIsClient } from '@cyberkongz/ui';
import { keyframes } from '@emotion/react';
import { PropsWithChildren } from 'react';

// ORIGINAL SOLUTION: https://codepen.io/sarazond/pen/LYGbwj
function randomNumber(range: number = 2000) {
  return Math.floor(Math.random() * range);
}

const animStar = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
`;

function generateBoxShadow(starsCount: number) {
  let str = '';

  for (let i = 0; i < starsCount; i = i + 1) {
    // max width of those is 4k px
    str = `${str} ${randomNumber(4000)}px ${randomNumber()}px #FFF ${
      i < starsCount - 1 ? ',' : ''
    }`;
  }

  return str;
}

const smallStarsBoxShadow = generateBoxShadow(1400);
const mediumStarsBoxShadow = generateBoxShadow(400);
const largeStarsBoxShadow = generateBoxShadow(200);

const STARS_ITEMS = [
  {
    boxShadow: smallStarsBoxShadow,
    size: '1px',
    animationDuration: '50s',
  },
  {
    boxShadow: mediumStarsBoxShadow,
    size: '2px',
    animationDuration: '100s',
  },
  {
    boxShadow: largeStarsBoxShadow,
    size: '3px',
    animationDuration: '150s',
  },
];

export function StarBackground({
  children,
  sxContainer,
  sxStarItem,
}: {
  sxContainer?: SystemStyleObject;
  sxStarItem?: SystemStyleObject;
} & PropsWithChildren) {
  const isClient = useIsClient();

  return (
    <Box position="relative" bg="background" sx={sxContainer}>
      <Box sx={{ overflow: 'hidden', ...fullSizeParentStyles }}>
        {isClient
          ? STARS_ITEMS.map(({ boxShadow, size, animationDuration }) => (
              <Box
                key={size}
                sx={{
                  width: size,
                  height: size,
                  background: 'transparent',
                  boxShadow,
                  animation: `${animStar} ${animationDuration} linear infinite`,
                  '&:after': {
                    content: '" "',
                    position: 'absolute',
                    top: '2000px',
                    width: 'inherit',
                    height: 'inherit',
                    background: 'inherit',
                    boxShadow: 'inherit',
                    zIndex: -3,
                  },
                  ...sxStarItem,
                }}
              />
            ))
          : null}
      </Box>
      {children}
    </Box>
  );
}
