import { Box, BoxProps, forwardRef, useMediaQuery, useMergeRefs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

type VideoAutoplayProps = BoxProps & {
  src: string;
  poster?: string;
  muted?: boolean;
  loop?: boolean;
  autoPlay?: boolean;
  fallbackVideoExtension?: 'mp4';
  posterExtension?: 'jpg' | 'png';
};

export const VideoAutoplay = forwardRef<VideoAutoplayProps, 'video'>(
  (
    {
      muted = true,
      loop = true,
      autoPlay = true,
      src,
      fallbackVideoExtension,
      posterExtension,
      ...rest
    },
    ref
  ) => {
    const { ref: refInView, inView } = useInView({
      threshold: 0,
      triggerOnce: true,
      rootMargin: '50px 0px',
    });
    const singleRef = useMergeRefs(ref, refInView);

    const [isSafari, setIsSafari] = useState<boolean>();

    //todo For future, to detect if device is mobile/tablet it has to use smth like:
    // const isTouchDevice = typeof window !== 'undefined' && 'ontouchstart' in window;
    const [isMobile] = useMediaQuery('(max-width: 1023px)', {
      ssr: true,
      fallback: undefined,
    });

    useEffect(() => {
      const _isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;

      setIsSafari(!!_isSafari);
    }, []);

    const extension = src.split('.')[1];
    const safariOnlyImage = extension === 'webm' && isSafari;

    return (
      <Box
        as="video"
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
        playsInline
        ref={singleRef}
        src={
          !safariOnlyImage
            ? isMobile || inView
              ? fallbackVideoExtension
                ? undefined
                : src
              : undefined
            : undefined
        }
        poster={posterExtension ? src.replace(extension, posterExtension) : undefined}
        {...rest}
      >
        {isMobile || (inView && fallbackVideoExtension) ? (
          <>
            {fallbackVideoExtension === 'mp4' ? (
              <source src={src.replace(extension, 'mp4')} type="video/mp4" />
            ) : null}
            <source src={src} type={`video/${extension}`} />
          </>
        ) : null}
      </Box>
    );
  }
);
