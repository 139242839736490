import { keyBy } from 'lodash-es';

type ProjectId = typeof PROJECTS[number]['id'];

export type ProjectData = {
  id: ProjectId;
  label: string;
  href: string;
  image: string;
  imageAlt?: string;
};

function getAssetsOthersImagePath(project: string) {
  return `/static/icons/projects/assets_and_others/${project}.png`;
}

function getPartnerImagePath(project: string, { alt = false } = {}) {
  return `/static/icons/projects/partners/${project}${alt ? '_alt' : ''}.png`;
}

// TO DO HREFS if needed
const ASSETS_AND_OTHERS = (
  [
    { id: 'axie_infinity', label: 'Axie Infinity', href: '/' },
    { id: 'ember_sword', label: 'Ember Sword', href: '/' },
    { id: 'guild_of_guardians', label: 'Guild of Guardians', href: '/' },
    { id: 'vivienne_tam', label: 'Vivienne Tam', href: '/' },
    {
      id: 'under_armour',
      label: 'Under Armour',
      href: '/',
    },
  ] as const
).map((project) => ({
  ...project,
  image: getAssetsOthersImagePath(project.id),
}));

// TO DO HREFS if needed
export const PARTNERS = (
  [
    { id: 'about_you', label: 'About You', href: '/' },
    { id: 'angry_dynomites_lab', label: 'Angrydynomites Lab', href: '/' },
    { id: 'aoe', label: 'AOE', href: '/' },
    { id: 'arcade_land', label: 'Arcade Land', href: '/' },
    { id: 'chainlink', label: 'Chainlink', href: '/' },
    { id: 'crypto_cities', label: 'Crypto Cities', href: '/' },
    { id: 'crypto_foxes', label: 'Crypto Foxes', href: '/' },

    { id: 'degenheim', label: 'Degenheim', href: '/' },
    {
      id: 'evaverse',
      label: 'Evaverse',
      href: '/',
    },
    { id: 'galaxy_fight_club', label: 'Galaxy Fight Club', href: '/' },
    {
      id: 'habbo_hotel',
      label: 'Habbo Hotel',
      href: '/',
    },
    { id: 'legends_of_venari', label: 'Legends of Venari', href: '/' },
    {
      id: 'metakey',
      label: 'Metakey',
      href: '/',
    },
    {
      id: 'metaresidence',
      label: 'MetaResidence',
      href: '/',
    },
    { id: 'nemus_earth', label: 'Nemus Earth', href: '/' },
    { id: 'nifty_league', label: 'Nifty League', href: '/' },
    {
      id: 'pepo_paradise',
      label: 'Pepo Paradise',
      href: '/',
    },
    {
      id: 'sandbox',
      label: 'The Sandbox',
      href: '/',
    },
    { id: 'shapeways', label: 'Shapeways', href: '/' },
    { id: 'sixty_two', label: 'Sixty Two', href: '/' },
    { id: 'solarbots', label: 'Solarbots', href: '/' },

    {
      id: 'the_littles',
      label: 'The Littles',
      href: '/',
    },
    {
      id: 'uniqly',
      label: 'Uniqly.io',
      href: '/',
      imageAlt: true,
    },
    { id: 'webb', label: 'World Wide Webb', href: '/' },
  ] as const
).map((project) => ({
  ...project,
  image: getPartnerImagePath(project.id),
  // @ts-ignore
  imageAlt: project.imageAlt ? getPartnerImagePath(project.id, { alt: true }) : undefined,
}));

const PROJECTS = [...PARTNERS, ...ASSETS_AND_OTHERS];

export const PROJECTS_BY_ID = keyBy(PROJECTS, 'id') as Record<ProjectId, ProjectData>;
