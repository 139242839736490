import { Button, ButtonProps } from '@chakra-ui/react';
import { AppLink } from '@cyberkongz/ui';

export function ButtonFullWidthMobile({
  children,
  variant = 'display',
  href,
  ...rest
}: Omit<ButtonProps, 'onClick'> & { href?: string; onClick?: () => void }) {
  return href ? (
    <AppLink variant="noHover" href={href}>
      <Button variant={variant} w={['100%', 'auto']} {...rest}>
        {children}
      </Button>
    </AppLink>
  ) : (
    <Button variant={variant} w={['100%', 'auto']} {...rest}>
      {children}
    </Button>
  );
}
